import React from 'react';
import { Box } from 'rebass';
import Layout from '../components/layout/Layout';
import MetaData from '../components/MetaData';
import JoinUsHero from '../sections/Landings/JoinUsHero';

const JoinUsPage = () => (
  <>
    <MetaData
      title='Join Us'
      description="Join us for a balanced life. We've connected 800+ developers with pioneering US startups for fulfilling remote roles."
    />
    <Layout selected='join-us'>
      <Box className='full-width' bg='nextonLightGrey' mb='150px' minHeight='calc(100vh - 100px)'>
        <JoinUsHero
          title='Your life-changing journey starts here'
          subtitle="Join the movement and live a more fulfilling and balanced life. We've been the bridge
          for more than 800 developers looking to become remote team members for groundbreaking US
          startups."
          HSFormID='3974d55f-cc4a-4f42-96d1-a4a0cb40a1dc'
        />
      </Box>
    </Layout>
  </>
);

export default JoinUsPage;
