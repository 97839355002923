import React, { useEffect } from 'react';
import { Box, Flex, Text } from 'rebass';
import FormBubbles from '../../components/academy/FormBubbles';
import { useHSFormsScriptLoaded } from '../../helpers/hooks';

const JoinUsHero = ({ title, subtitle, HSFormID }) => {
  const HSFormsScriptLoaded = useHSFormsScriptLoaded();

  useEffect(() => {
    if (HSFormsScriptLoaded) {
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '14573977',
        formId: HSFormID,
        target: '#join-us-form',
      });
    }
  }, [HSFormsScriptLoaded]);

  return (
    <Box className='body-layout'>
      <Flex flexWrap='wrap' pt='75px' style={{ position: 'relative', zIndex: '0' }}>
        <Box width={[1, 1, 1 / 2]} px={['0px', '20px']}>
          <Text
            color='nextonOceanBlue'
            fontSize={['44px', '60px']}
            lineHeight='1.16'
            letterSpacing='-1.5px'
            pt='55px'
            pb='45px'
            as='h1'
            fontWeight='400'>
            {title}
          </Text>
          <Text
            color='nextonDarkGrey'
            fontSize={['24px', '28px']}
            lineHeight='1.3'
            letterSpacing='-1.5px'
            pb='50px'
            as='h2'
            fontWeight='400'>
            {subtitle}
          </Text>
        </Box>
        <Box width={[1, 1, 1 / 2]} px={['0px', '20px']} mb='-100px'>
          <div id='join-us-form' className='nexton-form' />
        </Box>
        <FormBubbles />
      </Flex>
    </Box>
  );
};

export default JoinUsHero;
